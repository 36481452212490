@font-face {
  font-family: "poppins-bold";
  src: url("/fonts/Poppins-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "poppins-regular";
  src: url("/fonts/Poppins-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "poppins-semi-bold";
  src: url("/fonts/Poppins-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "poppins-medium";
  src: url("/fonts/Poppins-Medium.woff2") format("woff2");
  font-display: swap;
}

.petitionBody {
  * {
    font-size: 16px !important;
    line-height: 1.6em !important;
    color: #414141 !important;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  a {
    color: #6400ff !important;
  }
  p,
  span,
  div {
    line-height: 1.5em !important;
  }
  p,
  ul,
  ol {
    margin-top: 0 !important;
    margin-bottom: 10 !important;
  }
  ul,
  ol {
    padding-left: 40px !important;
  }
  ol {
    list-style-type: decimal !important;
  }
  ul {
    list-style-type: disc !important;
  }
  b,
  strong {
    font-family: "poppins-bold", Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
  }
}

.share_button a {
  font-family: "poppins-semi-bold", Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bold;
}

* {
  -webkit-font-smoothing: antialiased;
  font-family: "poppins-regular", Verdana, Geneva, Tahoma, sans-serif;
}

.priFont-extraBold {
  font-family: "poppins-bold", Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
}

.priFont-regular {
  font-family: "poppins-regular", Verdana, Geneva, Tahoma, sans-serif;
}

.priFont-medium {
  font-family: "poppins-medium", Verdana, Geneva, Tahoma, sans-serif;
}

.priFont-semiBold {
  font-family: "poppins-semi-bold", Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}
